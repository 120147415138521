var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return (_vm.data.items.length > 0)?_c('div',{staticClass:"block-type-102 grid-23"},[_c('div',{staticClass:"row"},[_vm._l((_vm.data.items),function(headline,index){return [_c('section',{key:index,staticClass:"block-type-102-headline-col",class:{
          'block-type-102-headline-col--onethird': index % 5 > 1,
          'block-type-102-headline-col--before-ad': _vm.getSettings.adsInject && _vm.getSettings.adsInject[index],
        },attrs:{"data-dropzone":"article-dropzone","data-container":"swappable-container"}},[_c('article',{staticClass:"block-type-102-headline",class:{
            'pt-content': _vm.ptContent(headline) && !_vm.politicalAd(headline) && !_vm.supportedContent(headline),
            'political-ad': _vm.politicalAd(headline) && !_vm.supportedContent(headline),
            'supported-content': _vm.supportedContent(headline),
          },attrs:{"data-draggable":"swappable-item"}},[_c('div',{staticClass:"row no-gutters align-items-center h-100"},[_c('div',{staticClass:"col-12 h-100"},[(_vm.getSettings.icons)?[_c('HeadlineIcons',{attrs:{"headline":headline}})]:_vm._e(),_vm._v(" "),_c('HeadlineImage',{staticClass:"block-type-102-headline__image",attrs:{"href":headline.href,"headline":headline,"picture-srcset":{ xs: { w: 384, h: 216 }, sm: { w: 882, h: 496 }, lg: { w: 1264, h: 711 } }}})],2),_vm._v(" "),_c('div',{staticClass:"block-type-102-headline__content"},[(_vm.getSettings.channel || _vm.getSettings.category)?_c('HeadlineLabels',{staticClass:"block-type-102-headline__labels",attrs:{"labels":_vm.labels(headline, _vm.domain),"paid":headline.content.paywall && headline.content.paywall.enabled}}):_vm._e(),_vm._v(" "),(_vm.getSettings.author)?_c('HeadlineAuthor',{attrs:{"authors":headline.authors}}):_vm._e(),_vm._v(" "),(_vm.getSettings.publishDate)?_c('HeadlinePublishDate',{attrs:{"date":headline.publishAt,"date-format":"YYYY [m.] MMMM DD [d.] HH:mm"}}):_vm._e(),_vm._v(" "),_c('HeadlineTitle',{staticClass:"block-type-102-headline__title",attrs:{"headline":headline,"title-size":index % 5 > 1 ? 'h4' : 'h2',"mobile-title-size":index % 5 === 0 ? 'h4' : 'h6',"title-comment-enabled":_vm.getSettings.titleComment}})],1)])])]),_vm._v(" "),(_vm.getSettings.adsInject && _vm.getSettings.adsInject[index])?[_c('AdFormBanner',{key:`adsInject-${index}`,attrs:{"ad-type":_vm.getSettings.adsInject[index].type,"settings":_vm.getSettings.adsInject[index]}})]:_vm._e()]})],2)]):_vm._e()
}
var staticRenderFns = []

export { render, staticRenderFns }
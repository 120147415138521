var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return (_vm.data.items.length > 0)?_c('div',{staticClass:"block-type-3 block-type-3--grid"},[_c('div',{staticClass:"row",attrs:{"data-container":"articles-container"}},[_vm._l((_vm.data.items),function(headline,index){return [_c('div',{key:index,staticClass:"block-type-3-headline-col",class:{ 'block-type-3-headline-col--grid-view': index !== 0 },attrs:{"data-dropzone":"article-dropzone","data-container":"swappable-container"}},[_c('article',{staticClass:"block-type-3-headline",class:{
            'pt-content': _vm.ptContent(headline) && !_vm.politicalAd(headline) && !_vm.supportedContent(headline),
            'political-ad': _vm.politicalAd(headline) && !_vm.supportedContent(headline),
            'supported-content': _vm.supportedContent(headline),
          },attrs:{"data-draggable":"swappable-item"}},[_c('div',{staticClass:"row no-gutters align-items-center h-100"},[_c('div',{staticClass:"block-type-3-headline__image-col h-100"},[(_vm.getSettings.icons)?[_c('HeadlineIcons',{attrs:{"headline":headline}})]:_vm._e(),_vm._v(" "),_c('HeadlineImage',{staticClass:"block-type-3-headline__image",attrs:{"href":headline.href,"headline":headline,"picture-srcset":index === 0
                    ? { xs: { w: 384, h: 216 }, sm: { w: 588, h: 331 }, lg: { w: 1264, h: 711 } }
                    : { xs: { w: 282, h: 188 }, sm: { w: 384, h: 216 }, lg: { w: 882, h: 496 } }}})],2),_vm._v(" "),_c('div',{staticClass:"block-type-3-headline__content-col"},[(_vm.getSettings.channel || _vm.getSettings.category)?_c('HeadlineLabels',{staticClass:"block-type-3-headline__labels",attrs:{"labels":_vm.labels(headline, _vm.domain),"paid":headline.content.paywall && headline.content.paywall.enabled}}):_vm._e(),_vm._v(" "),_c('HeadlineTitle',{staticClass:"block-type-3-headline__title",attrs:{"headline":headline,"title-size":"h4","mobile-title-size":index === 0 ? 'h2' : '',"title-comment-enabled":_vm.getSettings.titleComment}}),_vm._v(" "),(_vm.getSettings.lead)?_c('HeadlineLead',{staticClass:"block-type-3-headline__lead",attrs:{"lead":headline.content.lead}}):_vm._e(),_vm._v(" "),(_vm.getSettings.author)?_c('HeadlineAuthor',{staticClass:"block-type-3-headline__author color-black text-uppercase",attrs:{"authors":headline.authors}}):_vm._e(),_vm._v(" "),(_vm.getSettings.bookmark)?_c('HeadlineBookmarks',{attrs:{"headline-id":headline.id}}):_vm._e()],1)])])]),_vm._v(" "),(_vm.getSettings.adsInject && _vm.getSettings.adsInject[_vm.getGlobalIndex(index, _vm.getSettings.indexOffset)])?[_c('AdFormBanner',{key:`adsInject-${index}`,attrs:{"ad-type":_vm.getSettings.adsInject[_vm.getGlobalIndex(index, _vm.getSettings.indexOffset)].type,"settings":_vm.getSettings.adsInject[_vm.getGlobalIndex(index, _vm.getSettings.indexOffset)]}})]:_vm._e()]})],2)]):_vm._e()
}
var staticRenderFns = []

export { render, staticRenderFns }
var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return (_vm.data.items.length > 0)?_c('div',{class:`block-type-106 swiper-${_vm.blockKey}-wrapper`},[_vm._m(0),_vm._v(" "),_c('div',{attrs:{"data-container":"articles-container"}},[_c('Swiper',{attrs:{"class-name":`swiper-${_vm.blockKey}`,"swiper-options":_vm.swiperOptions}},_vm._l((_vm.data.items),function(headline,index){return _c('div',{key:index,staticClass:"swiper-slide"},[_c('div',{staticClass:"block-type-106-headline-col",attrs:{"data-dropzone":"article-dropzone","data-container":"swappable-container"}},[_c('article',{staticClass:"block-type-106-headline",class:{
              'pt-content': _vm.ptContent(headline) && !_vm.politicalAd(headline) && !_vm.supportedContent(headline),
              'political-ad': _vm.politicalAd(headline) && !_vm.supportedContent(headline),
              'supported-content': _vm.supportedContent(headline),
            },attrs:{"data-draggable":"swappable-item"}},[_c('div',{staticClass:"row no-gutters align-items-center h-100"},[_c('div',{staticClass:"col-12 h-100"},[(_vm.getSettings.icons)?[_c('HeadlineIcons',{attrs:{"headline":headline}})]:_vm._e(),_vm._v(" "),_c('HeadlineImage',{staticClass:"block-type-106-headline__image",attrs:{"href":headline.href,"headline":headline,"picture-srcset":{ xs: { w: 316, h: 316, r: '1:1' }, sm: { w: 882, h: 496 }, lg: { w: 1264, h: 711 } }}})],2),_vm._v(" "),_c('div',{staticClass:"block-type-106-headline__content"},[(_vm.getSettings.channel || _vm.getSettings.category)?_c('HeadlineLabels',{staticClass:"block-type-106-headline__labels",attrs:{"labels":_vm.labels(headline, _vm.domain),"paid":headline.content.paywall && headline.content.paywall.enabled}}):_vm._e(),_vm._v(" "),(_vm.getSettings.author)?_c('HeadlineAuthor',{attrs:{"authors":headline.authors}}):_vm._e(),_vm._v(" "),_c('HeadlineTitle',{staticClass:"block-type-2-headline__title",attrs:{"headline":headline,"title-size":"h4","mobile-title-size":"h6","title-comment-enabled":_vm.getSettings.titleComment,"show-views-count":_vm.getSettings.views,"show-video-duration":_vm.getSettings.videoDuration}})],1)])])])])}),0)],1),_vm._v(" "),_vm._m(1)]):_vm._e()
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',{staticClass:"block-type-106__nav-prev"},[_c('i',{staticClass:"fa-solid fa-chevron-left"})])
},function (){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',{staticClass:"block-type-106__nav-next"},[_c('i',{staticClass:"fa-solid fa-chevron-right"})])
}]

export { render, staticRenderFns }